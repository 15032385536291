import { Link } from "@StarberryUtils";
import React from "react";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll'

const Breadcrumbs =( props ) => {

  // property details url structure
  let uriStr = "";
  let textSaleRent = "";

  if ( props.property_breadcrumb.department === "residential") {
    if( props.property_breadcrumb.status === "for sale" ) {
      uriStr = `properties/for-sale/in-dubai`
    } else if ( props.property_breadcrumb.status === "for rent" ) {
      uriStr = `properties/for-rent/in-dubai`
    } else if ( props.property_breadcrumb.status === "sold" ) {
      uriStr = `properties/sold/in-dubai`
    } else if ( props.property_breadcrumb.status === "rented" ) {
      uriStr = `properties/let/in-dubai`
    }
  } else if ( props.property_breadcrumb.department === "commercial") {
    if( props.property_breadcrumb.search_type === "sales" ) {
      uriStr = `properties/commercial/for-sale/in-dubai`
    } else if ( props.property_breadcrumb.search_type === "lettings" ) {
      uriStr = `properties/commercial/for-rent/in-dubai`
    }
  }

  if( props.property_breadcrumb.status === "for sale" ) {
    textSaleRent = `For Sale`
  } else if ( props.property_breadcrumb.status === "for rent" ) {
    textSaleRent = `For Rent`
  } else if ( props.property_breadcrumb.status === "sold" ) {
    textSaleRent = `Sold`
  } else if ( props.property_breadcrumb.status === "rented" ) {
    textSaleRent = `Let`
  }
  
  // property details url structure

return(
  props.property_breadcrumb &&
  <div className="breadcrumb-block breadcrumb-content text-center" >
    <Container>
        <Row> 
          <Col lg={12}>
          <ScrollContainer className="scroll-container">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href={'/'+uriStr}>Properties {textSaleRent}</Breadcrumb.Item>
              {/* <Breadcrumb.Item href={'/'+uriStr}>{textSaleRent}</Breadcrumb.Item> */}
              <Breadcrumb.Item active>{props.property_breadcrumb.display_address}</Breadcrumb.Item>
            </Breadcrumb>
          </ScrollContainer>
          </Col>
        </Row>
    </Container>
  </div>
  )
}

export default Breadcrumbs;

