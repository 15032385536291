import  React, {useState} from "react"
import {Nav,Tab} from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';
import "./ProjectScore.scss"
// styles

// markup
const ProjectScore = ( props ) => {
  const ProjectScores = [
    {
      ProjectScoresValue:"5%",
      ProjectScoresName:"Internal Rate of Return"
    },{
      ProjectScoresValue:"4.5%",
      ProjectScoresName:"Yield"
    },
    {
      ProjectScoresValue:"1.35",
      ProjectScoresName:"Cash Multiple"
    }
  ]
  const [key, setKey] = useState('home');
  return (
    props.property_details_score_data.score &&
    <React.Fragment>
      <div className="project-score-section">
         <div className="animated"> 
          <div className="property-facts-title">
            <h2>Project Score</h2>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <div className="">
              <Nav variant="pills" className="flex-column">
                <div className="pills-wrap">
                    <Nav.Item>
                        <Nav.Link eventKey="1">Levered</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="2">Unlevered</Nav.Link>
                    </Nav.Item>
                </div>
              </Nav>
            </div>
            <div className="">
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <div className="project-score-list">
                    <ul>
                      {ProjectScores.map((item,index)=><li key={index}>
                        <strong>{item.ProjectScoresValue}</strong>
                        <span>{item.ProjectScoresName}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <strong>No Content</strong>
                </Tab.Pane>
              </Tab.Content>
            </div>
              </Tab.Container>
         </div>
      </div>
    </React.Fragment>
  )
}

export default ProjectScore