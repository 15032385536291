import React, { useState, useEffect } from "react" 
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PropertyDetail from "../components/PropertyDetail/PropertyDetail";
import "../components/PropertyDetail/PropertyDetail.scss"
// import { PropertyDetails } from "../queries/common_use_query"
import LoaderLogo from '../images/loader_logo_new.svg';
import {capitalize} from '../components/SearchResult/utils';
import { navigate } from "@reach/router";
import { graphql } from "gatsby";
import { removeDataAttributes } from "../comQueryStructure";
import { google_review_url } from "../components/common/utils";


const PropertyDetailsTemplate = (props) => {

  //console.log("pageContext ==>", props?.pageContext.id)

  const property_data = removeDataAttributes(props?.data)
  const propertyData = property_data?.glstrapi
    // Property details id fetch
    //console.log("property_data", property_data)

    let path_name = (props.location.pathname).split('/');
    let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];

    var get_id = get_url.split('-');
    var split_property_id = get_id[get_id.length-1];
  
    // const {loading, error, data:propertyData} = PropertyDetails(split_property_id);
    const [testimonials, setTestimonials] = useState([]);
    const [renderComponent, setRenderComponent] = useState(false);
    const strapiconfig = {
      headers: {
        Authorization:
          `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      },
    }
  
    useEffect(() => {
      let url = process.env.GATSBY_STRAPI_SRC + "/api/stb-google-reviews/google-review-reviews"
      getitems(url);
  
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
    }, []);
  
  
    var ggl_reviews = [];
    const getitems = async url => {
      try {
        const { data } = await axios.get(url, strapiconfig)// could be from env files
     
        if (data && data.length > 0) {
          data.map((item, index) => {
            //var star_no = wordsToNumbers(item.starRating);
                ggl_reviews.push(item);
          })
        }
        setTestimonials(ggl_reviews);
      } catch (error) {
        // cache it if fail/error;
        console.error(error)
      }
    }

    //console.log("propertyData", propertyData)

    // console.log("$$$$-----------", propertyData?.property?.search_type, propertyData?.property?.department, propertyData?.property?.status)

    // Property details id fetch
    var action_type = "";
    if(propertyData && propertyData.property){
        if(propertyData.property.status === "rented"){
            action_type = "let";
        } else{
            action_type = propertyData.property.status;
        }
    }

    let departmentType = "";
    if(propertyData && propertyData.property && propertyData.property.department === "commercial"){
        departmentType = propertyData && propertyData.property && propertyData.property.department+" ";
    }


    let bedroom_ttl = propertyData && propertyData.property && propertyData.property.bedroom ? propertyData.property.bedroom+" bedroom "+departmentType+propertyData.property.building : propertyData && propertyData.property && capitalize(departmentType)+propertyData.property.building;
    
    let property_title = propertyData && propertyData.property && bedroom_ttl+" "+capitalize(action_type)+" in "+propertyData.property.display_address;

    let description = propertyData && propertyData.property && "Find "+property_title+" with The Pearl Gates at AED "+propertyData.property.price+". Contact The Pearl Gates, for more information about the Properties.";

    
    var myMetaTitle = "";
    var myMetaDesc = "";

    if( props?.pageContext?.title ) {
        myMetaTitle = capitalize(props.pageContext.title);
        myMetaDesc = "Find the details of "+props.pageContext.title+" at AED "+props.pageContext.price.toLocaleString()+" with The Pearl Gates. Book a viewing today!";
    }

    useEffect(() =>{
      if(propertyData?.property?.publish === false) {
          navigate('/404')
      }
    },[propertyData])

    return(
        <Layout Layout={'Without_Banner'} classNames="Without_Banner" Search_Result={'Property_Details'} search_results_watsapplink={propertyData?.property?.search_type} propertyData={propertyData && propertyData.property} popularSearchForResults={true}>

            

            <Helmet/>
            {
             propertyData && <>
                <SEO title={myMetaTitle} description={myMetaDesc} />
                <PropertyDetail property_details_data={propertyData} location={props.location} h1_title={myMetaTitle} testimonials={testimonials}/>
                </>
            }

        </Layout>
    )
}

export const query = graphql`
query PropertyDetails($id: ID){
  glstrapi {
    property(id:$id) {
      data {
        id
        attributes {
          crm_id
          search_type
          department
          status
          images
          virtual_tour
          floorplan
          price
          display_address
          title
          long_description
          latitude
          longitude
          accommodation_summary
          facts
          score
          bedroom
          bathroom
          building
          address
          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms   
              }
            }
          }
          area
          negotiator_details
          propertyID
          furnished
          size
          sublisting_type
          lots
          project_rate
          sold_or_rented_date
          publish
        }
      }
    }
  }
}
`

export default PropertyDetailsTemplate
