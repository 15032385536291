
import  React, {useState} from "react"
import { Tab,Nav,Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyCalculators.scss"
import {calculateMonthlyPayment} from "../../../calculator/mortgage"
import {addCommas, removeNonNumeric, numericWithDecimal, CalculatorFields} from "../../common/utils"
// styles

// markup
const PropertyCalculators = (props) => {

  const [calculator_value, setCalculatorValue] = useState({'loan_amount':((70/100) * props.calc_price), 'purchase_price': props.calc_price, 'year_noi': parseFloat(props.calc_price*(6/100)).toFixed(2), 'cap_rate': 6, 'interest_rate':4.30, 'amortization': '30', 'down_payment':((30/100) * props.calc_price), 'select_caprate': 1, 'calculator_option':'caprate'});

  const handleChange = (event, field_name) => {
    //console.log("event_target", event.target.value)
    if(field_name === "select_caprate"){
        setCalculatorValue({...calculator_value, [field_name]: event.value});
    } else{
        if(event.target.name === "interest_rate" || event.target.name === "cap_rate"){
            setCalculatorValue({...calculator_value, [event.target.name]: event.target.value > CalculatorFields.InterestSliderMax ? CalculatorFields.InterestSliderMax : numericWithDecimal(event.target.value)})
        } else{
          if(event.target.name === "amortization"){
            if(parseInt(event.target.value) > 35){
          
            } else{
              setCalculatorValue({...calculator_value, [event.target.name]: numericWithDecimal(event.target.value)})
            }
          } else{
              setCalculatorValue({...calculator_value, [event.target.name]: numericWithDecimal(event.target.value)})
          }
        }
    }
    
  }

  const handleChange2 = (field_name, field_value) => {
    if(field_name === "interest_rate" || field_name === "cap_rate"){
        setCalculatorValue({...calculator_value, [field_name]: numericWithDecimal(parseFloat(field_value).toFixed(2))})
    } else{
      setCalculatorValue({...calculator_value, [field_name]: field_value > 0 ? numericWithDecimal(field_value) : 0});
    }
  }

  
  var year_noi_total =  (calculator_value.purchase_price * (calculator_value.cap_rate)/100).toFixed(2);
  var year_noi = (calculator_value.purchase_price * (calculator_value.cap_rate)/100).toFixed(2);
  var purchase_price_total = parseFloat((calculator_value.year_noi / calculator_value.cap_rate) * 100).toFixed(2);
  var cap_rate_total =  parseFloat((calculator_value.year_noi / calculator_value.purchase_price) * 100).toFixed(2);
  var equity_invested =  parseFloat(calculator_value.purchase_price - calculator_value.loan_amount).toFixed(2);
  var annual_loan_payment = parseFloat(calculateMonthlyPayment(calculator_value.purchase_price,calculator_value.interest_rate,calculator_value.down_payment,calculator_value.amortization) * 12).toFixed(2);
  var total_cash_flow = parseFloat(year_noi_total - annual_loan_payment).toFixed(2);
  var cash_on_cash_total = parseFloat((total_cash_flow/equity_invested) * 100).toFixed(2);

  var mortgage_monthly_payment =  parseFloat(calculateMonthlyPayment(calculator_value.loan_amount,calculator_value.interest_rate,calculator_value.down_payment,calculator_value.amortization)).toFixed(2);

  return (
  <React.Fragment>
   <div className="property-calculator-section">
    <div className="animated"> 
         <div className="property-calculator-title">
           <h2>Property Calculators</h2>
         </div>
         <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <div className="">
                  <Nav variant="pills" className="flex-column">
              <div className="pills-wrap">
                    <Nav.Item>
                        <Nav.Link eventKey="1">Cap Rate Calculator</Nav.Link>
                    </Nav.Item>
                     <Nav.Item>
                        <Nav.Link eventKey="2">Cash on Cash Calculator</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="3">Mortgage Calculator</Nav.Link>
                    </Nav.Item>
               </div>
               </Nav>
                
                  </div>
                  <div className="">
                      <Tab.Content>

                        <Tab.Pane eventKey="1">
                          <div className="project-fact-list">
                            <Form>
                              <ul>
                                  <li className="list_0"><strong className="list-title">Purchase Price <br className="d-sm-none"/>(AED) </strong>                                    
                                    <Form.Control type="text" name="purchase_price" value={addCommas(calculator_value.purchase_price)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax} />                                  
                                  </li>
                                  <li className="list_1"><strong className="list-title">Net Operating Income <br className="d-sm-none"/>(AED) </strong>
                                    <Form.Control type="text" name="year_noi" value={addCommas(calculator_value.year_noi)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax} />
                                  </li>
                                  <li className="list_2"><strong className="list-title">Cap Rate</strong><span>{parseInt(cap_rate_total) > -1 ? numericWithDecimal(cap_rate_total) : '0'}%</span></li>
                                  {/* <li className="list_1"><strong className="list-title">Cap Rate <br className="d-sm-none"/>(%) </strong>
                                    <Form.Control type="text" name="cap_rate" value={numericWithDecimal(calculator_value.cap_rate)} maxLength={CalculatorFields.InterestInputMax}/>
                                  </li> */}
                              </ul>
                            </Form>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="2">
                          <div className="project-fact-list prty_coc_sec">
                            <Form>
                              <ul>
                                  <li className="list_1"><strong className="list-title">Purchase  Price <br className="d-sm-none"/>(AED) </strong>
                                    <Form.Control type="text" name="purchase_price" value={addCommas(calculator_value.purchase_price)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax} />
                                  </li>
                                  <li className="list_1"><strong className="list-title">Cap Rate <br className="d-sm-none"/>(%) </strong>
                                    <Form.Control type="text" name="cap_rate" value={numericWithDecimal(calculator_value.cap_rate)} onChange={handleChange} maxLength={CalculatorFields.InterestInputMax}/>
                                  </li>
                                  <li className="list_1"><strong className="list-title">Loan Amount <br className="d-sm-none"/>(AED) </strong>
                                    <Form.Control type="text" name="loan_amount" value={addCommas(calculator_value.loan_amount)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax}/>
                                  </li>
                                  
                                  <li className="list_1"><strong className="list-title">Interest Rate <br className="d-sm-none"/>(%) </strong>
                                    <Form.Control type="text" name="interest_rate" value={numericWithDecimal(calculator_value.interest_rate)} onChange={handleChange} maxLength={CalculatorFields.InterestInputMax}/>
                                  </li>
                                  <li className="list_1"><strong className="list-title">Amortization <br className="d-sm-none"/>(Years) </strong>
                                    <Form.Control type="text" name="amortization" value={removeNonNumeric(calculator_value.amortization)} onChange={handleChange} maxLength={CalculatorFields.YearInputMax}/>
                                  </li>

                                  {/* <li className="list_1"><strong className="list-title">Annual Loan Payment <br className="d-sm-none"/>(AED)</strong>
                                    <Form.Control type="text"  name="annual_loan_payment" value={addCommas(annual_loan_payment)} readOnly/>
                                  </li> */}
                                  <li className="list_2"><strong className="list-title">Annual Loan Payment</strong><span>{annual_loan_payment ? addCommas(annual_loan_payment) : 0} AED</span></li>

                                  <li className="list_2"><strong className="list-title">Year 1 NOI</strong><span>{addCommas(year_noi_total) ? addCommas(year_noi_total) : 0} AED</span></li>
                                  <li className="list_2"><strong className="list-title">Equity Invested</strong><span>{addCommas(equity_invested) ? addCommas(equity_invested) : 0} AED</span></li>
                                  <li className="list_2"><strong className="list-title">Total Cash Flow After Debt Service</strong><span>{addCommas(total_cash_flow) ? addCommas(total_cash_flow) : 0} AED</span></li>
                                  <li className="list_2"><strong className="list-title">Year 1 Cash-on-Cash Return</strong><span>{parseInt(cash_on_cash_total) > -1 ? cash_on_cash_total : 0} %</span></li>
                              </ul>
                            </Form>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="3">
                          <div className="project-fact-list prty_coc_sec">
                            <Form>
                              <ul>
                                  <li className="list_0"><strong className="list-title">Loan Amount <br className="d-sm-none"/>(AED) </strong>
                                    <Form.Control type="text" name="loan_amount" value={addCommas(calculator_value.loan_amount)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax}/>
                                  </li>
                                  
                                  <li className="list_0"><strong className="list-title">Interest Rate <br className="d-sm-none"/>(%) </strong>
                                    <Form.Control type="text" name="interest_rate" value={numericWithDecimal(calculator_value.interest_rate)} onChange={handleChange} maxLength={CalculatorFields.InterestInputMax}/>
                                  </li>
                                  <li className="list_0"><strong className="list-title">Amortization <br className="d-sm-none"/>(Years) </strong>
                                    <Form.Control type="text" name="amortization" value={removeNonNumeric(calculator_value.amortization)} onChange={handleChange} maxLength={CalculatorFields.YearInputMax}/>
                                  </li>

                                  <li className="list_0"><strong className="list-title">Downpayment <br className="d-sm-none"/>(AED)</strong>
                                    <Form.Control type="text"  name="down_payment" value={addCommas(calculator_value.down_payment)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax} />
                                  </li>

                                  <li className="list_2"><strong className="list-title">Your monthly payment will be</strong><span>{addCommas(mortgage_monthly_payment) ? addCommas(mortgage_monthly_payment) : 0}  AED</span></li>
                              </ul>
                            </Form>
                          </div>
                        </Tab.Pane>

                      </Tab.Content>
                  </div>
                  </Tab.Container>
                   </div>
                   </div>
  </React.Fragment>
  )
}

export default PropertyCalculators